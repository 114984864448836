@import '~@fontsource/montserrat';
@import '~@fontsource/montserrat/500.css';
@import '~@fontsource/montserrat/700.css';
@import '~@fontsource/montserrat/800.css';
@import '~@fontsource/montserrat/900.css';
@import 'animate.css';


html {
  touch-action: pan-x pan-y;
  touch-callout: none;
  -webkit-user-select: none;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -ms-touch-action: pan-x pan-y;
  font-family: "Montserrat" !important;
}

body {
  margin: 0;
  font-family: 'Montserrat' !important;
}

code {
  font-family: 'Montserrat' !important;
}

$primary-color: #22aa27;
$primary-color-hover: #187e1b;
$secondary-color: #0e110e;
$secondary-color-optional: #0e110ec7;

/* Webkit (Chrome, Safari, Opera) */
::-webkit-scrollbar {
  width: 5px;
  background-color: rgba(0, 0, 0, 0.073);
}

::-webkit-scrollbar-thumb {
  background-color: $primary-color;
}

/* Firefox */
::-moz-scrollbar {
  width: 5px;
}

::-moz-scrollbar-thumb {
  background-color: $primary-color;
}

::-webkit-scrollbar-track {
  background-color: $secondary-color;
}

/* Firefox */
::-moz-scrollbar-track {
  background-color: $secondary-color;
}

/* IE, Edge */
::-ms-scrollbar-track {
  background-color: $secondary-color;
}

/* IE, Edge */
::-ms-scrollbar {
  width: 5px;
}

::-ms-scrollbar-thumb {
  background-color: $primary-color;
}

.base-content {
  min-height: 100vh;
  height: 100%;
  width: 100%;
}

.site-content {
  padding-left: 1rem;
  padding-right: 1rem;
}

.header-navbar-responsive {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  .header-menu-responsive {
    margin-right: 1rem;
  }

  .header-menu-icon {
    font-size: 1.5rem;
  }

  .header-navbar-title {
    text-align: center;
    flex-grow: 1;
  }
}

.dark-theme {
  background-color: $secondary-color;
  color: white;

  .header-navbar-responsive {
    background-color: transparent;

    &.header-scrolled {
      background-color: $secondary-color;
      border-bottom: 1px solid $primary-color-hover;
    }
  }
}

.light-theme {}

.header-navbar {
  position: fixed;
  width: 100%;
  z-index: 1;
}

.full-image-crop {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.full-image {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  background-color: $primary-color;
}

/* For mobile devices */
@media (max-width: 767px) {
  .full-image {
    min-height: 100vh;
    height: 100vh;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
  }
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.text-overlay-content {
  padding-left: 1rem;
  padding-right: 1rem;
}

.banner-title {
  font-weight: bold;
  // color: $primary-color !important;
  font-size: 2rem;
  // margin-bottom: 0.5rem;
}

.banner-subtitle {
  font-weight: bold;
  color: white !important;
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.bg-primary {
  background-color: $primary-color;
}

.bg-secondary {
  background-color: $secondary-color;
}

.card-home {
  @media (min-width: 821px) {
    min-height: 650px;
  }

  @media (max-width: 767px) {
    min-height: 250px;
  }

  .card-home-title {
    text-align: center;
    font-weight: bold;
  }

  .ant-card-body {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .card-home-text {
    font-size: 1rem;
    padding: 0;
    margin: 0;
  }
}

.section-services {
  margin-top: 2rem;

  .services-title {
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }
}

.margin-bottom-medium {
  margin-bottom: 1rem;
}

.margin-top-medium {
  margin-top: 1rem;
}

.margin-top-large {
  margin-top: 2rem;
}

.padding-bottom-large {
  padding-bottom: 3rem;
}

.desktop-image {
  width: 100%;
  display: block;
}

.mobile-image {
  display: none;
}

@media (max-width: 767px) {
  .desktop-image {
    display: none;
  }

  .mobile-image {
    display: block;
    width: 100%;
  }
}

.ant-float-btn-primary .ant-float-btn-body {
  background-color: #25d366;
}

.button-base {
  min-width: 15vw;
  text-transform: uppercase;
  font-weight: bold;

  @media (max-width: 821px) {
    min-width: 30vw;
  }

  @media (max-width: 767px) {
    min-width: 60vw;
  }
}

.button-primary {
  background: $primary-color;
  scale: 1.2;

  &:hover {
    background: $primary-color-hover !important;
  }
}

.button-secondary {
  background: $secondary-color;
  scale: 1.2;

  &:hover {
    background: $primary-color-hover !important;
  }
}

.horizontal-scroll-container {
  overflow-x: hidden;
  white-space: nowrap;
  position: relative;

  .content-wrapper {
    display: inline-block;
    animation: scroll 2s linear infinite;
    // background: red;
    margin-left: 1rem;
    margin-right: 1rem;

    img {
      height: 100px;
      border-radius: 50%;
    }
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.partner {
  cursor: pointer;

  img {
    height: 100px;
    border-radius: 50%;
  }
}

.section-full-screen {
  min-height: 100vh;
}

.ant-card-head {
  border-bottom: 0 !important;
}

.ant-card-cover {
  padding: 0.5rem;

  img {
    border-radius: 0 !important;
  }
}

.header-navbar-desktop {
  min-height: 100px;
  height: 100px;
  background: transparent;

  &.header-scrolled {
    background-color: $secondary-color;
    border-bottom: 1px solid $primary-color-hover;
  }
}

.header-desktop-navbar-menu {
  display: flex;

  .header-desktop-navbar-menu-logo {
    text-align: center;
  }

  .header-desktop-navbar-menu-item {
    margin-right: 1rem;
    font-size: 1rem;
    font-weight: bold;
    // border-right: 1px solid white;
    padding-right: .5rem;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
      color: $primary-color;
      border-color: $primary-color;
    }

    &:last-child {
      border: 0;
    }
  }
}

.min-full-height {
  min-height: 100vh;
}

.header-responsive-navbar-menu-logo {
  position: relative;
  display: flex;
  width: 100%;
  height: 60px;
  text-align: center;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-position: center center;
  align-items: center;
  justify-content: center;
  background-size: contain;
  cursor: pointer;

  img {
    display: none;
  }
}

.header-scrolled {
  .header-responsive-navbar-menu-logo {
    img {
      display: block;
    }
  }
}

.header-responsive-navbar-menu-bt {
  position: absolute;
  right: 20px;
  color: $primary-color;
  cursor: pointer;
}

.center-content {
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-wrap: wrap;
}

.card-team {
  @media (min-width: 767px) {
    min-height: 250px;
  }

  .card-team-title {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
  }

  .ant-card-body {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .card-team-text {
    font-size: 1rem;
    padding: 0;
    margin: 0;
  }
}

.social-icon {
  color: white;
  font-size: 1.7rem;

  &:hover {
    color: rgb(202, 202, 202);
  }
}

.social-icon-large {
  font-size: 3rem;
}

.social-square {
  padding: 1rem;
  background: $secondary-color;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #1a211a;
  }
}

.text-capitalize {
  text-transform: capitalize;
}

.ant-menu-item-selected {
  color: white !important;
  background-color: $primary-color-hover !important;
}

.clickable {
  cursor: pointer;
}

.social-image-icon {
  max-height: 50px;
}

.ant-float-btn-icon {
  width: 100% !important;
}

.float-button-image-icon {
  img {
    max-height: 25px;
  }
}

.i18n-language-option {
  display: flex;
  align-items: center;

  .i18n-language-label-option {
    margin-left: 0.5rem;
  }
}

.i18n-menu-mobile {
  padding-left: 24px;
  color: white;
  margin-top: 1rem;
}

.card-upcoming-event {
  cursor: pointer;

  .card-upcoming-event-title {
    text-align: left;
    font-weight: bold;
    font-size: 1.2rem;
    margin-top: 1rem;
  }

  .ant-card-body {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .card-upcoming-event-text {
    font-size: 1rem;
    padding: 0;
    margin: 0;
    margin-top: 1rem;
  }
}

.video-iframe {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 250px;
}

#section_upcoming_lives {
  min-height: 50vh;

  @media (max-width: 767px) {
    min-height: 100vh;
  }
}